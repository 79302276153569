import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { FootNote } from "../../../components/Blog/postElements/FootNote";
import { FootNotes } from "../../../components/Blog/postElements/FootNotes";
import { PostIntro } from "../../../components/Blog/postElements/PostIntro";
import { FullWidthImage } from "../../../components/Blog/postElements/FullWidthImage";
export const _frontmatter = {
  "title": "What is the future of mobile wallets?",
  "categories": ["business", "fintech", "mobile Payments", "flutter"],
  "authors": ["Piotr Gąsierkiewicz"],
  "abstract": "The popularity of mobile wallet payments has moved past the early adopter phase into majority acceptance. What lies ahead for businesses entering this fast-moving marketplace? Which factors will be the most significant? Read the article to find out more.",
  "imageSource": "./main.png",
  "link": "future-of-mobile-payments",
  "date": "2021-05-10T05:49:01.441Z",
  "seoTitle": "Future of the mobile payments",
  "seoDescription": "the mobile wallet market as it is now, the main market drivers and how it may change in the future",
  "seoKeyPhrase": "what are the mobile wallet trends in 2021",
  "seoKeyWords": "mobile wallets trends popular, wallets app, mobile wallet, e wallets, digital wallet, wallet solutions, m wallet, e wallet app"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <PostIntro title="Introduction" description={<p><b>This article is the first in a series created to support FinTech entrepreneurs creating new products in this fast-moving marketplace. In the first instalment, we look at the mobile wallet market, as it is now and how it may change in the future.</b></p>} imageUrl={"/img/1.-mobile-wallet-trends-present-and-future.png"} mdxType="PostIntro" />
    <p>{`The popularity of payments using mobile wallets continues to increase as more vendors begin to accept them, and more consumers realise their convenience.`}</p>
    <p>{`In 2017, the global mobile wallets market was worth `}<strong parentName="p">{`$368 billion`}</strong>{`. In the next two years, the value jumped to over `}<FootNote number="1" isBold={true} content="$745.7 billion" mdxType="FootNote" />{`. The market growth seems unstoppable, but is it still growing and will that continue?`}</p>
    <p>{`The short answer is `}<strong parentName="p">{`YES`}</strong>{`.`}</p>
    <p>{`2020 was the first year that more than 1 billion people worldwide used mobile payment apps to pay in-store, at least every six `}<FootNote number="2" content="months" mdxType="FootNote" />{`.`}</p>
    <p>{`It was also the year the entire mobile wallet market became a `}<strong parentName="p">{`$1 trillion worth industry`}</strong>{`, growing by a massive `}<strong parentName="p">{`36.5% year-on-year`}</strong>{`. The strong rising trend is forecast to continue in 2021 and the following years also, with the entire market reaching `}<strong parentName="p">{`$2.1 trillion value in`}</strong>{` `}<FootNote number="3" isBold={true} content="2023" mdxType="FootNote" />{`.`}</p>
    <p><strong parentName="p">{`So what are the major trends & drivers in this fast-moving area?`}</strong></p>
    <h2 {...{
      "id": "Market-drivers"
    }}>{`Market drivers`}</h2>
    <FullWidthImage imageSrc='/img/futureOfMobileWallets/future-wallets-1.png' imageAlt='Future wallets' mdxType="FullWidthImage" />
    <p>{`The major ongoing trend is that cash continues its decline with `}<strong parentName="p">{`mobile payments picking up the change. 2020 was the year that mobile payment overtook cash as the second most popular payment method`}</strong>{`, after debit card `}<FootNote number="4" content="payments" mdxType="FootNote" />{`. Soon, most of us will be living cashless.`}</p>
    <p>{`What drives the change in how we interact with those we buy from, and the way we manage our money?`}</p>
    <p>{`Mobile payments are `}<strong parentName="p">{`easier and more convenient`}</strong>{` than a credit card or cash. Mobile phone payment is contactless, so customers do not need to present cards or cash.`}</p>
    <p>{`Mobile wallet payments can easily be `}<strong parentName="p">{`integrated with loyalty and incentive programs`}</strong>{`. Unlike cards or cash, all the necessary information is stored in the app with every purchase they make.`}</p>
    <p>{`Valuable `}<strong parentName="p">{`customer data`}</strong>{` can also be accessed through mobile payments, such as how often they shop, what they buy, and their preferred mode of payment. This information can be used by vendors to target their customers more accurately.`}</p>
    <p><strong parentName="p">{`Mobile payments are extremely secure`}</strong>{`. Most mobile payment applications are `}<strong parentName="p">{`tokenised`}</strong>{`, meaning that personal information entered is verified by the app and then held as a “token” that stands-in for the customers’ personal data. In addition to this, each transaction is made using that token integrated with `}<strong parentName="p">{`a one-time-use security pin`}</strong>{`. This makes it extremely difficult for malicious actors to misuse a customers account, without access to their mobile phone.`}</p>
    <p>{`Mobile payments provide security `}<strong parentName="p">{`even in a case where the mobile phone is stolen`}</strong>{` as the payment information `}<strong parentName="p">{`remains secure behind a passcode or biometric authentication (or both)`}</strong>{`.`}</p>
    <p><strong parentName="p">{`The attraction and usefulness of mobile wallets and their complementary apps are apparent. But what uses are currently growing in popularity?`}</strong></p>
    <h2 {...{
      "id": "Fast-growing-varieties"
    }}>{`Fast-growing varieties`}</h2>
    <FullWidthImage imageSrc='/img/futureOfMobileWallets/future-wallets-2.png' imageAlt='Fast growing varieties' mdxType="FullWidthImage" />
    <p>{`FinTech has spent the last decade absorbing traditional financial services, making them hugely `}<strong parentName="p">{`more accessible while at the same time adding utility`}</strong>{`. Consumers can now access wallets and associated apps `}<strong parentName="p">{`across all these areas of their financial lives`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`Retail payments`}</li>
      <li parentName="ul">{`E-commerce transactions`}</li>
      <li parentName="ul">{`Bill splitting`}</li>
      <li parentName="ul">{`Personal budgeting`}</li>
      <li parentName="ul">{`Expenditure tracking`}</li>
      <li parentName="ul">{`Digital bank accounts`}</li>
      <li parentName="ul">{`Multiple bank account data consolidation`}</li>
      <li parentName="ul">{`Savings and investments`}</li>
      <li parentName="ul">{`Loans`}</li>
      <li parentName="ul">{`Insurance`}</li>
    </ul>
    <p>{`Indicators of the growth within consumer markets can be found everywhere. There’s China’s `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://intl.alipay.com",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Alipay`}</a></strong>{`, which recently overtook `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://www.wechat.com",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`WeChat`}</a></strong>{` as mainland China’s most popular payment provider. In South American, consumer payment app Pago saw its user base grow `}<strong parentName="p">{`155%`}</strong>{` and total payment volume through the digital wallet increase `}<strong parentName="p">{`299%`}</strong>{` to `}<FootNote number="5" isBold={true} content="$1.3 billion" mdxType="FootNote" />{`.`}</p>
    <p>{`Within our business lives, there’s an ever-increasing diversity of `}<strong parentName="p">{`wallet-connected banking`}</strong>{` and `}<strong parentName="p">{`financial tools for SMEs, micro-businesses and freelancers`}</strong>{`, all of which help entrepreneurs gain control, have peace of mind and also lighten the management task-load:`}</p>
    <ul>
      <li parentName="ul">{`Invoices and payments`}</li>
      <li parentName="ul">{`Mobile banking`}</li>
      <li parentName="ul">{`Business accounting`}</li>
      <li parentName="ul">{`Expense tracking`}</li>
      <li parentName="ul">{`Cashflow management`}</li>
      <li parentName="ul">{`Tax record preparation and filingW`}</li>
      <li parentName="ul">{`Business financing`}</li>
    </ul>
    <p>{`Some of the companies offering hope of a better life to small business owners and entrepreneurs include `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://www.kabbage.com",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Kabbage`}</a></strong>{`, which has provided $4 billion of funding to more than 100,000 small businesses in the US, and they are now `}<strong parentName="p">{`worth over $1.3`}</strong>{` `}<FootNote number="6" isBold={true} style={{
        right: "2px"
      }} content="billion." mdxType="FootNote" />{` Much smaller is UK-based `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://www.getcoconut.com",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Coconut`}</a></strong>{`, offering micro-business owner `}<strong parentName="p">{`banking combined with accounts and tax automation`}</strong>{`. In the past year, `}<a parentName="p" {...{
        "href": "https://www.getcoconut.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Coconut`}</a>{` has grown to 25,000 registrations, `}<strong parentName="p">{`a growth of more than 3x`}</strong>{`.`}</p>
    <p>{`Some mobile wallet providers even manage to span both personal and business use. `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://www.apple.com/apple-pay/",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`ApplePay`}</a></strong>{` is an example of this, with a prolific collection of `}<strong parentName="p">{`connected apps targeting both personal and business users`}</strong>{`.`}</p>
    <p><strong parentName="p">{`Let’s take a look at the most noteworthy providers, where they operate and which regional factors might offer opportunities for FinTech entrepreneurs.`}</strong></p>
    <h2 {...{
      "id": "Where-Are-They-Popular"
    }}>{`Where Are They Popular?`}</h2>
    <p>{`Top global providers of mobile wallets and associated apps tend to be divided into two, regionally. There are those operating within mainland China and those prevalent elsewhere.`}</p>
    <h3 {...{
      "id": "In-China"
    }}>{`In China`}</h3>
    <p><a parentName="p" {...{
        "href": "https://intl.alipay.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Alipay`}</a>{` is the world’s biggest mobile payment platform with more than `}<strong parentName="p">{`1.2 billion daily active`}</strong>{` `}<FootNote number="7" isBold={true} content="users" mdxType="FootNote" />{`.`}</p>
    <h3 {...{
      "id": "Elsewhere"
    }}>{`Elsewhere`}</h3>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://www.apple.com/apple-pay/",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`ApplePay`}</a></strong>{` had 441 million daily active users in 2019 (up from 292 million users the previous year).`}</p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://www.paypal.com",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Paypal`}</a></strong>{` has 305 million daily active users (Q1-2020 saw year-on-year growth of 17%).`}</p>
    <h3 {...{
      "id": "Slow-US-mobile-payment-adoption"
    }}>{`Slow US mobile payment adoption`}</h3>
    <p>{`Mostly, this is due to the momentum of traditional payment methods in the US domestic market. China, by contrast, mostly leapfrogged these conventional payment processing methods, going direct to an explosion in mobile payments.`}</p>
    <p>{`Also, public distrust. `}<strong parentName="p">{`40% of US consumers who don’t use mobile payments cite security concerns as a major`}</strong>{` `}<FootNote number="8" isBold={true} content="reason" mdxType="FootNote" />{`.`}</p>
    <h3 {...{
      "id": "Regions-with-fast-growth-rate"
    }}>{`Regions with fast growth rate`}</h3>
    <p>{`Within 2019, India (26.4%), Indonesia (24.3%), Mexico (20.3%) and Germany (20.1%) `}<strong parentName="p">{`were the fastest-growing markets`}</strong>{`. Retailers adding point-of-sale (POS) terminals to support proximity mobile payments will largely be responsible for driving this `}<FootNote number="9" content="growth" mdxType="FootNote" />{`.`}</p>
    <h3 {...{
      "id": "Which-territories-will-come-to-the-fore-in-the-near-future"
    }}>{`Which territories will come to the fore in the near future?`}</h3>
    <p>{`Economies in the Southern Hemisphere such as the `}<strong parentName="p">{`Philippines, Indonesia`}</strong>{`, parts of `}<strong parentName="p">{`Latin America`}</strong>{` and countries across `}<strong parentName="p">{`Sub-Saharan Africa`}</strong>{` have largely been under-banked or un-banked for decades, but this is changing due to the rise of mobile payment and wallet innovations. `}<strong parentName="p">{`Mobile sales for these regions are anticipated to rise into the billions`}</strong>{` `}<FootNote number="10" isBold={true} content="soon" mdxType="FootNote" />{`.`}</p>
    <h2 {...{
      "id": "What-else-is-causing-the-change"
    }}>{`What else is causing the change?`}</h2>
    <p><strong parentName="p">{`Millennials are pushing the financial sector towards change`}</strong>{`. They started looking for work during the post-2008 recession. Many see they stand on the wrong side of the wealth gap and are likely also carrying heavy student loan debt.`}</p>
    <p>{`A high proportion of millennials lack financial education. `}<strong parentName="p">{`They make up for this by using multiple apps - for investing, budgeting and saving, and so on`}</strong>{`.`}</p>
    <p>{`They need different solutions and are happy to embrace unconventional `}<FootNote number="11" content="approaches" mdxType="FootNote" />{`.`}</p>
    <h2 {...{
      "id": "What-lies-ahead"
    }}>{`What lies ahead? `}</h2>
    <p>{`These factors will be among the most significant within 2021:`}</p>
    <p><strong parentName="p">{`Entrepreneurs`}</strong>{` will reduce their reliance on traditional professional services and, instead, use software solutions, many connected to mobile wallets.`}</p>
    <p>{`Previously inaccessible `}<strong parentName="p">{`markets will open up`}</strong>{`. Regions with under-banked or unbanked populations will create huge growth in numbers of active users, especially in the Southern Hemisphere.`}</p>
    <p><strong parentName="p">{`Millennials`}</strong>{` will drive availability of more comprehensive, interoperable and diverse financial services on smartphones.`}</p>
    <p>{`Lastly, we can’t end this article without mentioning how `}<strong parentName="p">{`awareness of the Covid-19`}</strong>{` `}<strong parentName="p">{`pandemic is driving increased use of mobile wallets`}</strong>{`. According to Statista `}<FootNote number="12" content="data" mdxType="FootNote" />{`, the global mobile wallet industry will jump almost 50% amid the COVID-19 outbreak, reaching `}<strong parentName="p">{`$1.47trn value within 2020`}</strong>{`.`}</p>
    <FootNotes footnotes={[{
      "value": "https://www.paymentscardsandmobile.com/global-mobile-wallet-market-value-set-to-reach-1-trillion-in-2020/",
      "link": "https://www.paymentscardsandmobile.com/global-mobile-wallet-market-value-set-to-reach-1-trillion-in-2020/"
    }, {
      "value": "https://www.emarketer.com/content/global-mobile-payment-users-2019",
      "link": "https://www.emarketer.com/content/global-mobile-payment-users-2019"
    }, {
      "value": "https://www.paymentscardsandmobile.com/global-mobile-wallet-market-value-set-to-reach-1-trillion-in-2020/",
      "link": "https://www.paymentscardsandmobile.com/global-mobile-wallet-market-value-set-to-reach-1-trillion-in-2020/"
    }, {
      "value": "https://www.merchantsavvy.co.uk/mobile-payment-stats-trends/",
      "link": "https://www.merchantsavvy.co.uk/mobile-payment-stats-trends/"
    }, {
      "value": "https://www.fool.com/investing/2020/06/18/where-will-mercadolibre-be-in-5-years.aspx",
      "link": "https://www.fool.com/investing/2020/06/18/where-will-mercadolibre-be-in-5-years.aspx"
    }, {
      "value": "https://www.growthmanifesto.com/kabbage-growth-strategy",
      "link": "https://www.growthmanifesto.com/kabbage-growth-strategy"
    }, {
      "value": "https://www.merchantsavvy.co.uk/mobile-payment-stats-trends/",
      "link": "https://www.merchantsavvy.co.uk/mobile-payment-stats-trends/"
    }, {
      "value": "https://www.simon-kucher.com/sites/default/files/2019-01/SimonKucher_Report_Payment%20Adoption_Final.pdf",
      "link": "https://www.simon-kucher.com/sites/default/files/2019-01/SimonKucher_Report_Payment%20Adoption_Final.pdf"
    }, {
      "value": "https://www.emarketer.com/content/global-proximity-mobile-payment-users",
      "link": "https://www.emarketer.com/content/global-proximity-mobile-payment-users"
    }, {
      "value": "https://www.hlb.global/fintech-and-the-future-of-finance/",
      "link": "https://www.hlb.global/fintech-and-the-future-of-finance/"
    }, {
      "value": "https://www.bankrate.com/personal-finance/millennials-changing-financial-services/",
      "link": "https://www.bankrate.com/personal-finance/millennials-changing-financial-services/"
    }, {
      "value": "https://buyshares.co.uk/mobile-wallet-payments-to-jump-50-amid-covid-19-outbreak-and-hit-1-47trn-value-in-2020/",
      "link": "https://buyshares.co.uk/mobile-wallet-payments-to-jump-50-amid-covid-19-outbreak-and-hit-1-47trn-value-in-2020/"
    }]} mdxType="FootNotes" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      